import React, { useState, useEffect } from "react";
import logo from "./three-dots.svg";
import "./App.css";

const api = process.env.REACT_APP_API_URL;

function App() {
  const [url, setUrl] = useState("");
  const [param, setParam] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    console.log(`${api}/u/${param}`);
    fetch(`${api}/u/${param}`)
      .then((res) => res.json())
      .then(({ data, err }) => {
        if (data) {
          setUrl(data);
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.substring(1); // Remove leading slash
    setParam(id);
  }, []);

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  useEffect(() => {
    if (param) {
      fetchData();
    }
  }, [param]);

  return (
    <div className="App">
      {loading ? (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" width="50px" />
        </header>
      ) : null}
    </div>
  );
}

export default App;
